import Token from "./config/Token"

const token = new Token()
const endpoint = process.env.MIX_APP_URL + '/api/payment'

export async function newTicketPix(sale, sendWhatsApp = true, showMessageCardRejected = true) {
    return axios.post(`${endpoint}/mercado-pago/ticket-pix/${sale}`,
        {sendWhatsApp, showMessageCardRejected},
        {
            headers: {'Authorization': `Bearer ${await token.getToken()}`},
        }
    )
}

export async function updateLead(bankTransferId, status, message) {
    return axios.put(`${endpoint}/update-bank-transfer-status`,
        {bankTransferId, status, message},
        {
            headers: {'Authorization': `Bearer ${await token.getToken()}`}
        }
    )
}
