import store from '../store'

export const hasTwoFactorAuthentication = (to, from, next) => {
    if (to.path == '/enable-two-factor-auth' && window.user?.twoFactorEnabled) {
        next({ name: 'sale-atendimento' });
    }
    else if (to.path == '/enable-two-factor-auth') {
    }
    else if (!window.user?.twoFactorEnabled) {
        next({ name: 'enable-two-factor-auth' });
    }
}
