import {getCookieToken, setCookieToken} from './CookieToken'

export default class Token {
    getResponse(){
        return this.response
    }

    getError(){
        return this.error
    }

    async getToken(){
        const token = getCookieToken()
        if (token) {
            return token
        } else {
            alert('Faça login novamente')
            window.location.href = '/login'
        }
    }
}
