import Cookies from 'js-cookie'

const tokenKey = 'auth_token_sf'

export function getCookieToken() {
    return Cookies.get('auth_token_sf')
}

export function setCookieToken(token) {
    const inTwentyMinutes = new Date(new Date().getTime() + 20 * 60 * 1000);
    return Cookies.set(tokenKey, token, { expires: inTwentyMinutes })
}

export function removeCookieToken() {
    return Cookies.remove(tokenKey)
}
