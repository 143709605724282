import Vue from 'vue';
import Vuex from 'vuex';
import * as painel from './modules/painel.js';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        painel,
    },
    state: {
        count: 0
    },
    mutations: {
        increment (state) {
            state.count++
        }
    },
    actions:{}
});
