import { newTicket } from '../../services/Payment';

export const namespaced = true
export const state = {
    events: [],
    casa: 55,
    event: {}
}
export const mutations = {
    ADD_EVENT(state, event) {
        state.events.push(event)
    },
    SET_EVENTS(states, events) {
        states.events = events
    },
    SET_TOTAL_PAGES(states, total) {
        states.totalPages = total
    },
    SET_EVENT(states, event) {
        states.event = event
    }
}
export const actions = {
    fetchSales({ commit, dispatch }, { perPage, page }) {

    },
    fetchSale({ commit, getters, dispatch }, id) {

    },
}
export const getters = {
    getSaleById: state => id => {
        return state.events.find(event => event.id === id)
    }
}
