import Echo from 'laravel-echo';

import Pusher from 'pusher-js';
import {getCookieToken} from "./services/config/CookieToken";
window.Pusher = Pusher;
const token = getCookieToken()


window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    wsHost: process.env.MIX_PUSHER_APP_HOST,
    wsPort: 80,
    forceTLS: true,
    disableStats: true,
    encrypted: true,
    wssPort: 443,
    enabledTransports: ['ws', 'wss'],
    auth: {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    },
});
